
@media screen and (max-width: 914px) {
  .links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    height: 0px;
    transition: all 0.5s;
    width: 100%;
  
  }
  .mynav {
    flex-direction: column;
  }
  .btnshow {
    display: block;
  }
}
@media screen and (min-width: 914px){
  .btnshow {
    display: none;
  }
}

.linknav {
  font-size: 50px;
  transition: ease 0.5s;
  cursor: pointer;
}

.linknav:hover {
  transform: scale(1.2);
}

.navs {
  transition: ease 0.5s;
}

.mynav  a { 
  color: white;
  text-decoration: none;
  padding: 0px 20px;
  font-weight: 700;
}
.links a:hover {
 color: turquoise;
}
.links a {
  transition: all 0.5s;
}
.mynav-cont {
  position: fixed;
  top: 0;
  width: 100%;
  transition: 0.5s;
}

.mycontbackg{
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  width: 100%;
  transition: 0.8s;
  
}
.mycontbackg2{
  background-color: rgb(0,0,0);
  position: fixed;
  top: 0;
  width: 100%;
  transition: 0.8s;
}




/* styling for the input for on contact us page */
.customForm {
  margin: 30px 0;
  padding: 0 5px;
}
.customForm .emailinput .input {
  border: none;
  outline: none;
  background-color: inherit;
  width: 100%;
  z-index: 4;
  color: white;
  height: 40px;
  font-size: 16px;
}
.customForm .emailinput  {
  border-bottom: 2px solid black;
  position: relative;
  padding: 0 5px;
}
.customForm .emailinput label {
  position: absolute;
  display: flex;
  left: 5px;
  z-index: 1;
  top: 50%;
  transition: .5s;
  font-size: 16px;
  color: #adadad;
  transform: translateY(-50%);
}

.customForm .emailinput span::before {
  content: '';
  position: absolute;
  top:40px;
  left: 0;
  width: 0%;
  transition: .5s;
  height: 2px;
  background: #2691d9;
}

.customForm .emailinput input:focus ~ label,
.customForm .emailinput input:valid ~ label{
  color: #2691d9;
  top: -5px;
}
.customForm .emailinput input:valid ~ span::before,
.customForm .emailinput input:focus ~ span::before{
  width: 100%;
}